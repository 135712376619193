import styled from 'styled-components';
import React from 'react';
import 'moment/locale/nl';
import scoreMappers from '../ScoreMappers';
import CompareSection from './CompareSection';
import itemBlocksContent from '../itemBlocksContent';
import BottomContentSection from './BottomContentSection';

function SiegeSectionComponent({ scans, mainUrl, className }) {
  return (
    <div className={className}>
      <ContentContainer>
        <div>
          <CompareSection
            mapper={scoreMappers.siege}
            scans={scans}
            mainUrl={mainUrl}
          />
        </div>
        <BottomContentSection items={itemBlocksContent.siege} />
      </ContentContainer>
    </div>
  );
}

const ContentContainer = styled.div`
  padding: 0 4rem 0 7rem;
`;

const SiegeSection = styled(SiegeSectionComponent)``;

export default SiegeSection;
