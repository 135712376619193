import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/nl';
import Logo from '../../components/logo';
import { useScans } from '../../helpers/get-scan-results';
import PerfscanLoader from '../performance-scan/components/PerfscanLoader';
import ErrorLoader from '../performance-scan/components/ErrorLoader';
import menu from './Menu';
import SiegeSection from './components/SiegeSection';
import SpeedSection from './components/SpeedSection';
import UxContentSection from './components/UxContentSection';
import OverallSection from './components/OverallSection';
import dashboard from './Dashboard';
import DashboardSection from './components/DashboardSection';


moment.locale('en');

const PerformanceScanCompare = ({ className, heroBlockImage }) => {
  const { data, errors, loading } = useScans();
  const [showOverall, setShowOverall] = useState(true);
  const [showSiege, setShowSiege] = useState(false);
  const [showSpeed, setShowSpeed] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showFullDashboard, setShowFullDashboard] = useState(false);
  const [mobileState, setMobileState] = useState(false);
  const [isActive, setIsActive] = useState('');

  const clearState = () => {
    setShowOverall(false);
    setShowContent(false);
    setShowSpeed(false);
    setShowSiege(false);
    setShowFullDashboard(false);
  };

  if (loading) {
    return <PerfscanLoader data={data} />;
  }

  if (errors) {
    return <ErrorLoader errors={errors} />;
  }

  const scans =
    data.length === 1
      ? data[0].tests
      : data.map((testRequests) => testRequests.tests[0]);

  const [mainScan] = scans;
  const urls = scans.map((scan, i) => {
    let data = [];
    data['url'] = scan.friendlyTestUrl.replace(/(www.|\/$)/g, '');
    data['style'] = i == 0 ? 'large' : 'medium';
    return data;
  });
  const mainUrl = urls.shift();

  const handleStyle = (target) => {
    if (isActive !== '') {
      const comp = document.getElementsByName(isActive);
      if (comp !== undefined) {
        comp[0].classList.remove('activeButton');
      }
    }
    target.classList.add('activeButton');
  };

  const handleClick = (event) => {
    clearState();
    event.preventDefault();
    handleStyle(event.target);
    setIsActive(event.target.name);

    switch (event.target.name) {
      case 'contentSection':
        setShowContent(true);
        break;
      case 'speedSection':
        setShowSpeed(true);
        break;
      case 'siegeSection':
        setShowSiege(true);
        break;
      case 'overallSection':
        setShowOverall(true);
        break;
      case 'dashboardSection':
        setShowFullDashboard(true);
        break;
      default:
        setShowOverall(true);
        break;
    }
  };

  const menuItems = menu.items.map((item) => (
    <button name={item.key} onClick={handleClick.bind(this)}>
      {' '}
      {item.title}
    </button>
  ));

  const toggleMobile = (value) => () => {
    setMobileState(value);
  };

  const subUrlBlock = urls.map((url) => (
    <span className={`suburl ${url.style}`}>+ {url.url.replace(`${mainUrl.url}`, '')}</span>
  ));

  return (
    <PerfScanContainer>
      <Menu>
        <StyledLogo />
        <h4 className='light'>{menu.title}</h4>
        <div>
          <h2 className={mainUrl.style}>{mainUrl.url}</h2>
          {subUrlBlock}
        </div>
        <p className='light'>
          {menu.moment} {moment(scans[0].created.date).fromNow()}
        </p>
        {menuItems}
      </Menu>
      <div className='contentSection'>
        {showSiege ? (
          <SiegeSection scans={scans} mainUrl={mainUrl.url} />
        ) : null}
        {showSpeed ? (
          <SpeedSection
            scans={scans}
            mobileState={mobileState}
            mainUrl={mainUrl.url}
          />
        ) : null}
        {showContent ? (
          <UxContentSection
            scans={scans}
            mobileState={mobileState}
            mainUrl={mainUrl.url}
          />
        ) : null}
        {showOverall ? (
          <OverallSection scans={scans} mobileState={mobileState} />
        ) : null}
        {showFullDashboard ? <DashboardSection dashboard={dashboard} /> : null}
        <MobileDesktopToggler>
          <input
            id='desktop'
            name='state-d'
            type='radio'
            aria-label='Desktop'
            checked={`${mobileState ? '' : 'checked'}`}
          />
          <label
            htmlFor='desktop'
            onClick={toggleMobile(false)}
            onKeyDown={toggleMobile(false)}
          >
            DESKTOP
          </label>
          <input
            id='mobileState'
            name='state-d'
            type='radio'
            aria-label='Mobile'
            checked={`${mobileState ? 'checked' : ''}`}
          />
          <label
            htmlFor='mobileState'
            onClick={toggleMobile(true)}
            onKeyDown={toggleMobile(true)}
          >
            MOBILE
          </label>
        </MobileDesktopToggler>
      </div>
    </PerfScanContainer>
  );
};

const StyledLogo = styled(Logo)`
  max-width: 10rem;
  width: 100%;
`;

const Menu = styled.div`
  min-width: 20%;
  font-family: 'Airbnb Cereal App Medium';
  background: #fff1e5 0% 0% no-repeat padding-box;
  padding: 2em 0 2.5em 1.5em;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  word-wrap: break-word;
  color: #312b48 p {
    padding-bottom: unset;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    height: 20%;

    button {
      padding: 0 1rem;
    }

    .activeButton {
      border-bottom: 5px solid;
      border-image-source: linear-gradient(
        90deg,
        rgb(233, 30, 99),
        rgb(252, 128, 14)
      );
    }

    .small {
      font-size: 0.9rem;
    }

    .medium {
      font-size: 1.1rem;
    }
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}),
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    min-width: 20%;
    line-height: 2.5rem;
    height: 100%;

    button {
      display: block;
      line-height: 3.5rem;
    }

    .suburl {
      display: block;
      line-height: 1.8em;
    }

    .activeButton {
      border-left: 8px solid;
      border-bottom: 0;
      border-image-source: linear-gradient(rgb(233, 30, 99), rgb(252, 128, 14));
    }

    .small {
      font-size: 0.9rem;
    }

    .medium {
      font-size: 1rem;
    }
  }
`;

const MobileDesktopToggler = styled.div`
  display: flex;
  justify-content: center;
  background: #fff;
  position: fixed;
  bottom: 5%;
  right: 40%;
  border-radius: 50px;
  box-shadow: 0px 0px 16px 5px rgb(0 0 0 / 50%);
  
  label {
    font-size: 1rem;
    padding: 1rem;
    border-radius: 50px;
    cursor: pointer;
    font-family: 'Airbnb Cereal App Medium';
  }

  input {
    position: absolute;
    opacity: 0;
  }
  
  input + label {
    background: #fff;
    color: orange;
  }
  
  input:checked + label {
    color: #fff;
    background: linear-gradient(90deg, rgb(233, 30, 99), rgb(252, 128, 14));
  }
`;

const PerfScanContainer = styled.div`
  margin-top: 3rem;

  .activeButton {
    color: #fa7a18;
    border-image-slice: 1;
  }

  .light {
    font-family: 'Airbnb Cereal App Light';
  }

  p {
    padding-bottom: 0.5em;
  }

  li {
    padding: 1em 0;
  }

  button {
    background: none !important;
    border: none;
    cursor: pointer;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    min-width: 100%;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}),
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: flex;
  }
`;

export default PerformanceScanCompare;
