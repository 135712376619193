const itemBlocksContent = {
  overall: {
    items: [
      {
        icon: 'fa-dashboard',
        title: 'Webshop performance',
        content:
          `Waiting for a webshop is no longer accepted in 2021. 
          Both the visitors and the most used search engine “Google” are raising the bar. 
          This means that as an online store you must have a continuous focus on your performance in order not to fall behind. 
          With Hipex's performance scan, you can see at a glance how your webshop is performing in terms of speed, lighthouse and peak resistance. 
          By continuously focusing on this, we enable you to optimize the right aspects of your website.`,
      },
      {
        icon: 'fa-dashboard',
        title: 'Hipex Performance Score',
        content:
          `The HPS is calculated based on the overall performance of your website. 
          This includes both the frontend and backend, and it's compared against market data.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://www.hipex.io/docs/en/performance-scan/scan/',
          text: 'Read more about HPS',
        },
      },
      {
        icon: 'fa-dashboard',
        title: 'Lighthouse scores',
        content:
          `Google Lighthouse is a tool that should provide websites with more insight in optimizing both your mobile site and the desktop version. 
          The tool contains all kinds of different parameters mainly related to User Experience. 
          The performance scan provides insight into, among other things, the largest content-rich paint, total blocking time and the cumulative layout shift. 
          In addition, you can also see how your website is performing in terms of speed index, time to interactive and server response time.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://developers.google.com/web/tools/lighthouse/',
          text: 'Read more about Lighthouse',
        },
      },
    ],
    title: 'Share this report with the rest of your team',
    share: {
      title: 'Copy link',
      link: '',
      icon: '',
    },
    download: {
      title: 'Download report',
      icon: '',
    },
  },
  siege: {
    items: [
      {
        icon: 'fa-dashboard',
        title: 'What is a stress test?',
        content:
          `With a stress test you can find out how many visitors can perform numerous actions on your website. 
          It is of course very important that your caching is properly set up so that static pages can be served from memory and therefore only dynamic pages consume significantly more resources. 
          By setting this up properly, you can handle more visitors. And to test where the limit lies, you can perform a stress test to gain more insight into this.`,
      },
      {
        icon: 'fa-dashboard',
        title: 'Need a more serious load test?',
        content:
          `We regularly help our customers with setting up and running larger cloud based load tests. 
          This can especially be useful when a sale starts or when running a big marketing campaing like TV commercials. 
          By setting up a Hipex Load Test well in advance, you can gain insight into whether you may need to scale up so that you can handle more visitors or whether your current environment is still sufficient. 
          We also regularly request access to Google Analytics to help analyze.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://www.hipex.io/en/contact/',
          text: 'I\'m interested',
        },
      },
    ],
    title: 'Share this report with the rest of your team',
    share: {
      title: 'Copy link',
      link: '',
      icon: '',
    },
    download: {
      title: 'Download report',
      icon: '',
    },
  },
  speed: {
    items: [
      {
        icon: 'fa-dashboard',
        title: 'Speed Index',
        content:
          `The Speed Index is a metric that Google uses to measure how quickly content is loaded.
          The Speed Index has a considerable influence on the final score and is therefore important not to underestimate.
          There are many ways to optimize your content so that it can be loaded earlier.
          This not only benefits the user experience, but also improves the speed of your website.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://www.hipex.io/docs/en/performance-scan/speed-index/',
          text: 'Read more about Speed Index',
        },
      },
      {
        icon: 'fa-dashboard',
        title: 'Time To Interactive (TTI)',
        content:
          `An increasingly important metric is the Time To Interactive. This metric measures how long a website needs to load until there is interactivity. 
          You speak of an interactive page when you as a visitor can do something on a specific page. 
          Think of clicking through or putting a product in the shopping cart so that you can actually buy it.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://www.hipex.io/docs/en/performance-scan/interactive/',
          text: 'Read more about TTI',
        },
      },
      {
        icon: 'fa-dashboard',
        title: 'Server Response Time (SRT) ',
        content:
          `The Server Response Time (before also known as Time To First Byte) is the time that a visitor has to wait from the moment he executes a click (request) on a page until the server can answer that click. 
          Simply put, it means that Google measures how long the server needs to serve a specific request. 
          This is a very important metric that helps to make websites faster.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://www.hipex.io/time-to-first-byte-ttfb/',
          text: 'Read our blog about SRT',
        },
      },
    ],
    title: 'Share this report with the rest of your team',
    share: {
      title: 'Copy link',
      link: '',
      icon: '',
    },
    download: {
      title: 'Download report',
      icon: '',
    },
  },
  content: {
    items: [
      {
        icon: 'fa-dashboard',
        title: 'Largest Contentful Paint (LCP)',
        content:
          `In many cases, a page consists of all kinds of different elements. This can be a text block, image or, for example, a video. 
          The Largest Contentful Paint indicates how long it takes for the largest element of a page to be shown to the visitor. 
          By optimizing this you can achieve a lot of profit which will not only lead to much higher scores but of course also make the website much faster.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://www.hipex.io/docs/en/performance-scan/largest-contentful-paint/',
          text: 'Read more',
        },
      },
      {
        icon: 'fa-dashboard',
        title: 'Total Blocking Time (TBT)',
        content:
          `An important part of Core Web Vitals is the total blocking time. 
          This metric measures how much time elapses between the First Contentful Paint and the Time To Interactive while the primary thread is blocked, causing a temporary inability to interact. 
          Google speaks of a block when the primary thread is blocked for more than 50 ms. 
          The Total Blocking Time indicates the total blocking time of all tasks that prevent a browser from processing interaction requests while performing these tasks.`,
        link: {
          icon: 'fa-dashboard',
          action: 'https://www.hipex.io/docs/en/performance-scan/total-blocking-time/',
          text: 'Read more',
        },
      },
      {
        icon: 'fa-dashboard',
        title: 'Cumulative layout shift (CLS)',
        content:
          `Within Lighthouse there are all kinds of different metrics that together determine how a website functions. 
          The Cumulative Layout Shift is a metric that provides insight into the visual stability of all pages. 
          This mainly focuses on elements that move unexpectedly, causing the layout of a page to change. 
          This may have the consequence that a visitor, for example, clicks just next to the Call To Action button.`,
      },
    ],
    title: 'Share this report with the rest of your team',
    share: {
      title: 'Copy link',
      link: '',
      icon: '',
    },
    download: {
      title: 'Download report',
      icon: '',
    },
  },
};

export default itemBlocksContent;
