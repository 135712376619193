import styled from 'styled-components';
import React from 'react';
import 'moment/locale/nl';
import scoreMappers from '../ScoreMappers';
import CompareSection from './CompareSection';
import itemBlocksContent from '../itemBlocksContent';
import BottomContentSection from './BottomContentSection';

function UxContentSectionComponent({
  scans,
  mobileState,
  mainUrl,
  className,
}) {
  const mapper = mobileState ? scoreMappers.content.mobile : scoreMappers.content.desktop;
  return (
    <div className={className}>
      <ContentContainer>
        <div>
          <CompareSection
            mapper={mapper}
            scans={scans}
            mainUrl={mainUrl}
          />
        </div>
        <BottomContentSection
          items={itemBlocksContent.content}
        />
      </ContentContainer>
    </div>
  );
}

const ContentContainer = styled.div`
  padding: 0 4rem 0 7rem;
`;

const UxContentSection = styled(UxContentSectionComponent)`
`;

export default UxContentSection;
