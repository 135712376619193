import React from 'react';
import styled from 'styled-components';
import ScoreBar from './ScoreBar';

function CompareRowComponent({
  title,
  scans,
  map,
  mainUrl,
  subTitle,
  className,
  suffix,
  aimForHigh,
  titleFontStyle,
  scoreFontStyle,
  showDiff,
  scoreRange,
}) {
  const titleComp = (
    <Title>
      <p>{title} </p>
      <p className='subTitle'>{subTitle}</p>
    </Title>
  );

  if (!suffix) {
    suffix = '';
  }

  const barComp = scoreRange ? <ScoreBar
    scoreRange={scoreRange}
    suffix={suffix}
  /> : '';

  let scoresComp = [];

  if (map) {
    const scores = scans.map((scan) => ({
      ...scan,
      displayScore: map(scan)
    }));

    scoresComp = scores.map(({ displayScore, ...scan }, index) => {
      let resultColor = '#312B48';

      if (barComp) {
        const min = scoreRange.moderate.low;
        const max = scoreRange.moderate.high;

        if (displayScore <= min) {
          resultColor = '#43AC24';
        } else if (displayScore >= max) {
          resultColor = '#ED120E';
        } else {
          resultColor = '#FE9F45';
        }
      }

      const style = {
        color: resultColor
      };
      const score = [(
        <span
          key={`${scan.testUrl}-${title}-${index}`}
          style={style}
        >{suffix ? displayScore : displayScore || '00'} {suffix}
        </span>
      )];

      let url = scan.friendlyTestUrl.replace(/(www.|\/$)/g, '');
      if (url !== mainUrl) {
        url = url.replace(`${mainUrl}`, '');
      }

      return (
        <Flex
          direction="row"
          justify="center"
          items="center"
          weight="Medium"
          size="2.2rem"
          key={`${title}-${scan.testUrl}`}
        >
          <span className="urlMobileSection">
            {url}
          </span>
          <span className="scoreSection">
            {score}
          </span>
        </Flex>
      );
    });
  } else {
    scoresComp = scans.map((scan) => {
      let url = scan.friendlyTestUrl.replace(/(www.|\/$)/g, '');
      if (url !== mainUrl) {
        url = url.replace(`${mainUrl}`, '');
      }

      return (
        <Flex
          direction="row"
          justify="center"
          items="center"
          weight="Medium"
          key={`${title}-${scan.testUrl}`}
        >
          {url}
        </Flex>
      );
    });
  }

  const classes = title ? className : `desktopTitle ${className}`;

  return (
    <div className={classes}>
      <Flex direction="column" justify="center">
        {titleComp}
        {barComp}
      </Flex>
      {scoresComp}
    </div>
  );
}

CompareRowComponent.defaultProps = {
  boldTitle: false,
  aimForHigh: true,
  titleFontStyle: {},
  scoreFontStyle: {},
  showDiff: true,
};
const Title = styled.span`
  padding-bottom: .8rem;
  p {
    font-family: ${(props) => props.theme.fontFamilyMedium};
    padding: 0 !important;
    line-height: 1.4rem;
  }
  
  .subTitle {
    font-family: ${(props) => props.theme.fontFamilyLight};
  }
`;

const Flex = styled.div`
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    display: flex;
  }

  ${(props) => (props.direction ? `flex-direction: ${props.direction};` : null)}
  ${(props) => (props.justify ? `justify-content: ${props.justify};` : null)}
  ${(props) => (props.items ? `align-items: ${props.items};` : null)}
  ${(props) => (props.weight ? `font-family: Airbnb Cereal App ${props.weight};` : null)}
  ${(props) => (props.size ? `font-size: ${props.size};` : null)}
`;

const CompareRow = styled(CompareRowComponent)`
  grid-template-columns: .75fr repeat(${(props) => (props?.scans?.length || 0)}, 1fr);

  background: linear-gradient(${(props) => (props?.title ? '#F2F4F6' : '#ffffff')}, ${(props) => (props?.title ? '#F2F4F6' : '#ffffff')}) 100% 0% / 97% 100% no-repeat;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    display: block;
    margin-bottom: 1.5rem;

    .scoreSection, .urlMobileSection {
      padding-left: 2rem;
    }

    .urlMobileSection {
      padding-bottom: .3rem;
      font-size: 1.2rem;
      display: block;
    }
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    display: grid;
    margin-bottom: 1rem;

    .scoreSection, .urlMobileSection {
      padding-left: 0;
    }

    .urlMobileSection {
      display: none;
    }
  }

  > div {
    padding: .75rem 0 calc(.75rem + 4px) 0;
  }
  
  margin-bottom: .5rem;
  }
`;

export default CompareRow;
