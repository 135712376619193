import styled from 'styled-components';
import React from 'react';
import 'moment/locale/nl';
import itemBlocksContent from '../itemBlocksContent';
import BottomContentSection from './BottomContentSection';
import scoreMappers from '../ScoreMappers';
import ScoreBlock from './ScoreBlock';

import ScreenshotSection from './ScreenshotSection';

function OverallSectionComponent({ scans, mobileState, className }) {
  const scan = scans[0];
  const finalScreenshot = scan?.lighthouse?.desktop.audits?.find(
    (o) => o.id === 'final-screenshot'
  ).details.data;
  const screenshotThumbnails = scan?.lighthouse?.desktop.audits?.find(
    (o) => o.id === 'screenshot-thumbnails'
  )?.details.items;

  const mapper = mobileState
    ? scoreMappers.overall.mobile
    : scoreMappers.overall.desktop;
  const blocks = mapper.items.map((item) => (
    <ScoreBlock item={item} scan={scan} />
  ));

  return (
    <div className={className}>
      <ContentContainer>
        <div className='top'>
          <div className='metrics'>{blocks}</div>
          <div className='screenshot'>
            <ScreenshotSection
              scan={scan}
              screenshots={finalScreenshot}
              thumbnails={screenshotThumbnails}
            />
          </div>
        </div>
        <div className='bottom'>
          <BottomContentSection items={itemBlocksContent.overall} />
        </div>
      </ContentContainer>
    </div>
  );
}

const ContentContainer = styled.div`
  padding: 0 4rem 0 7rem;
`;

const OverallSection = styled(OverallSectionComponent)`
  .top {
    display: flex;
    margin-bottom: 2em;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}),
    @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    .metrics {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
`;

export default OverallSection;
