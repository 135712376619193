import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/button';
import i18n from '../../../i18n';
import Video from '../../../components/video';

function DashboardSectionComponent({ dashboard, className }) {
  return (
    <div className={className}>
      <div className="backgroundImage">
        <img src={'/img/performance-dashboard.png'} alt={'performance-dashboard'}/>
      </div>
      <div className="content">
        <p>
          <h1>
            {dashboard.title}
          </h1>
          {dashboard.content}
          <p>
            <Button
              variant="primaryGradient"
              url={i18n('ctaTryForFreeButtonUrl', '/try-us-free/')}
              analyticsEvent="freeTrialHeader"
            >
              {i18n('ctaTryForFreeButtonText', 'Gratis uitproberen')}
            </Button>
          </p>
        </p>
        <p>
          <Video
            url={dashboard.videoUrl}
            />
        </p>
      </div>
    </div>
  );
}

const DashboardSection = styled(DashboardSectionComponent)`
  h1 {
     font-family: "Airbnb Cereal App Medium";
     padding-bottom: 1rem;
  }
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    display: block;
    
    p {
      padding: 3rem 2rem;
      display: block;
    } 
    
    .backgroundImage {
      display: none;
    }
     
    .content {
      position: relative;
    }
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
   display: flex;
   
    p {
      padding: 3rem 2rem;
      flex: 1 0 33%;
    }
    
    .content {
      position: absolute;
      display: flex;
    }
  
    .backgroundImage {
      display: block;
    }
  }
  
  .backgroundImage {
    filter: blur(25px);
    -webkit-filter: blur(25px);

    padding: 1rem;
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export default DashboardSection;
