import React from 'react';
import styled from 'styled-components';
import CompareRow from './CompareRow';

function CompareSectionComponent({ mapper, scans, mainUrl, className }) {
  const comps = mapper.items.map((item, index) => (
    <CompareRow
      key={item.title}
      {...item}
      scans={scans}
      mainUrl={mainUrl}
      subTitle={item.subTitle}
      isLast={index === mapper.items.length - 1}
      scoreRange={item.scoreRange ? item.scoreRange : false}
    />
  ));

  if (mapper.title) {
    return (
      <div className={className}>
        <CompareRow scans={scans} mainUrl={mainUrl} />
        {comps}
      </div>
    );
  }
  return (
    <div className={className}>
      {comps}
    </div>
  );
}

const CompareSection = styled(CompareSectionComponent)`
  margin-bottom: 3rem;
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    .desktopTitle {
      display: none;
    }
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    .desktopTitle {
      display: grid;
    }
  }
`;

export default CompareSection;
