import styled from 'styled-components';
import React from 'react';

function BottomContentItemComponent({
  item,
  className,
}) {
  return (
    <div className={className}>
      <i className={item.icon} /><h3>{item.title}</h3>
      <p>{item.content}</p>
      {item.link && (
        <a className="actionLink" href={item.link.action} target="_blank" rel="noreferrer">
          <i className={item.link.icon} />
          {item.link.text}
        </a>
      )}
    </div>
  );
}

const BottomContentItem = styled(BottomContentItemComponent)`
  font-family: "Airbnb Cereal App Light";
  
  background: linear-gradient(#FDF1F3, #FDF1F3) 100% 0% / 95% 100% no-repeat;
  flex: 1 0 33%;
  line-height: 1.7rem;

  .actionLink {
    justify-content: flex-end;
  }

  h3 {
    font-size: 1.2em;
    font-weight: bold;
    font-family: "Airbnb Cereal App Medium";
    padding: 1em 0;

  }
`;

export default BottomContentItem;
