import styled from 'styled-components';
import React from 'react';
import BottomContentItem from './BottomContentItem';

function BottomContentComponent({ items, className }) {
  const blocks = items.items.map((item) => <BottomContentItem item={item} />);

  return (
    <div className={className}>
      {blocks}
      <div className="actionsSection">
        <h3>{items.title}</h3>
        <br />
        <div className="actionLinks">
          <p className="actionLink">{items.share.title}</p>
          <p className="actionLink">{items.download.title}</p>
        </div>
      </div>
    </div>
  );
}

const BottomContentSection = styled(BottomContentComponent)`

  gap: 2em;

  .actionsSection {
    margin-top: 2rem;
    text-align: center;
    flex: 1 0 33%;
    font-family: 'Airbnb Cereal App Medium';
  }

  .actionLinks {
    display: flex;
    justify-content: center;
  }

  .actionLink {
    display: flex;
    font-family: 'Airbnb Cereal App Medium';
    margin: 2rem;
    color: #fa7b15;
    text-decoration: none;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    display: block;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}),
    @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

export default BottomContentSection;
