import React from 'react';
import styled from 'styled-components';
import 'moment/locale/nl';
import SiteScreenshot from '../../performance-scan/site-screenshot';
import FriendlyTestUrl from './FriendlyTestUrl';

function ScreenshotSectionComponent({
  scan,
  mainScan,
  startClip,
  stopClip,
  showClip,
  heroBlockImage,
  finalScreenshot,
  screenshotThumbnails,
  className,
}) {
  return (
    <div className={className}>
      <ContentContainer>
        <ScreenshotWrapper>
          <SiteScreenshot
            parentControlStartClip={startClip}
            parentControlStopClip={stopClip}
            parentControlShowClip={showClip}
            key={`${scan.friendlyTestUrl}-screenshots`}
            friendlyTestUrl={scan.friendlyTestUrl}
            heroBlockImage={heroBlockImage}
            finalScreenshot={finalScreenshot}
            lighthouse={scan.lighthouse}
            thumbnails={screenshotThumbnails}
            showFox={false}
            showClip={false}
            // imageBackgroundColor={colors[index]}
          />
          <a href={scan.testUrl} target='_blank' rel='noopener noreferrer'>
            <FriendlyTestUrl test={scan} mainTest={mainScan} />
          </a>
        </ScreenshotWrapper>
      </ContentContainer>
    </div>
  );
}

const ContentContainer = styled.div`
  padding: 0 4rem 0 7rem;
`;

const ScreenshotWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScreenshotSection = styled(ScreenshotSectionComponent)``;

export default ScreenshotSection;
