const scoreMappers = {
  overall: {
    title: '',
    desktop: {
      items: [{
        title: 'Hipex Performance Score',
        map: (testRun) => parseInt(testRun.score, 10),
        scoreRange: {
          moderate: {
            low: 50,
            high: 89,
          },
        },
      }, {
        title: 'Lighthouse Score',
        map: (testRun) => parseInt(testRun.lighthouse.desktop.categories.find((category) => category.id === 'performance').score * 100, 10),
        scoreRange: {
          moderate: {
            low: 50,
            high: 89,
          },
        },
      }, {
        title: 'SEO Score',
        map: (testRun) => parseInt(testRun.lighthouse.desktop.categories.find((category) => category.id === 'seo').score * 100, 10),
        scoreRange: {
          moderate: {
            low: 50,
            high: 89,
          },
        },
      }],
    },
    mobile: {
      items: [{
        title: 'Hipex Performance Score',
        map: (testRun) => parseInt(testRun.score, 10),
        scoreRange: {
          moderate: {
            low: 50,
            high: 89,
          },
        },
      }, {
        title: 'Lighthouse Score',
        map: (testRun) => parseInt(testRun.lighthouse.mobile.categories.find((category) => category.id === 'performance').score * 100, 10),
        scoreRange: {
          moderate: {
            low: 50,
            high: 89,
          },
        },
      }, {
        title: 'SEO Score',
        map: (testRun) => parseInt(testRun.lighthouse.mobile.categories.find((category) => category.id === 'seo').score * 100, 10),
        scoreRange: {
          moderate: {
            low: 50,
            high: 89,
          },
        },
      }],
    }
  },
  speed: {
    desktop: {
      title: 'desktop',
      items: [{
        title: 'Loading',
        subTitle: 'Speed Index',
        map: (testRun) => parseFloat(testRun.lighthouse.desktop.audits.find((audit) => audit.id === 'speed-index').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 3.4,
            high: 5.8,
          },
        },
      }, {
        title: 'Speed',
        subTitle: 'First Contentful Paint',
        map: (testRun) => parseFloat(testRun.lighthouse.desktop.audits.find((audit) => audit.id === 'first-contentful-paint').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 1.8,
            high: 3,
          },
        },
      }, {
        title: 'Interactivity',
        subTitle: 'Time To Interactive',
        map: (testRun) => parseFloat(testRun.lighthouse.desktop.audits.find((audit) => audit.id === 'interactive').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 3.9,
            high: 7.3,
          },
        },
      }, {
        title: 'Backend Speed',
        subTitle: 'Server Response Time',
        map: (testRun) => parseFloat(testRun.lighthouse.desktop.audits.find((audit) => audit.id === 'server-response-time').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 3.9,
            high: 7.3,
          },
        },
      }],
    },
    mobile: {
      title: 'Mobile',
      items: [
        {
          title: 'Loading',
          subTitle: 'Speed Index',
          map: (testRun) => parseFloat(testRun.lighthouse.mobile.audits.find((audit) => audit.id === 'speed-index').numericValue / 1000).toFixed(2),
          suffix: 's',
          aimForHigh: false,
          scoreRange: {
            moderate: {
              low: 3.4,
              high: 5.8,
            },
          },
        }, {
          title: 'Speed',
          subTitle: 'First Contentful Paint',
          map: (testRun) => parseFloat(testRun.lighthouse.mobile.audits.find((audit) => audit.id === 'first-contentful-paint').numericValue / 1000).toFixed(2),
          suffix: 's',
          aimForHigh: false,
          scoreRange: {
            moderate: {
              low: 1.8,
              high: 3,
            },
          },
        }, {
          title: 'Interactivity',
          subTitle: 'Time To Interactive',
          map: (testRun) => parseFloat(testRun.lighthouse.mobile.audits.find((audit) => audit.id === 'interactive').numericValue / 1000).toFixed(2),
          suffix: 's',
          aimForHigh: false,
          scoreRange: {
            moderate: {
              low: 3.9,
              high: 7.3,
            },
          },
        }, {
          title: 'Backend Speed',
          subTitle: 'Server Response Time',
          map: (testRun) => parseFloat(testRun.lighthouse.mobile.audits.find((audit) => audit.id === 'server-response-time').numericValue / 1000).toFixed(2),
          suffix: 's',
          aimForHigh: false,
          scoreRange: {
            moderate: {
              low: 3.9,
              high: 7.3,
            },
          },
        }],
    },
  },
  content: {
    desktop: {
      title: 'Desktop',
      items: [{
        title: 'Loading',
        subTitle: 'Largest Contentful Paint',
        map: (testRun) => parseFloat(testRun.lighthouse.desktop.audits.find((audit) => audit.id === 'largest-contentful-paint').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 2.5,
            high: 4,
          },
        },
      }, {
        title: 'Interactivity',
        subTitle: 'Total Blocking Time',
        map: (testRun) => parseFloat(testRun.lighthouse.desktop.audits.find((audit) => audit.id === 'total-blocking-time').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 0.2,
            high: 0.6,
          },
        },
      }, {
        title: 'Visual Stability',
        subTitle: 'Cumulative Layout Shift',
        map: (testRun) => parseFloat(testRun.lighthouse.desktop.audits.find((audit) => audit.id === 'cumulative-layout-shift').numericValue).toFixed(2),
        scoreRange: {
          moderate: {
            low: 0.1,
            high: 0.25,
          },
        },
      }],
    },
    mobile: {
      title: 'Mobile',
      items: [{
        title: 'Loading',
        subTitle: 'Largest Contentful Paint',
        map: (testRun) => parseFloat(testRun.lighthouse.mobile.audits.find((audit) => audit.id === 'largest-contentful-paint').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 2.5,
            high: 4,
          },
        },
      }, {
        title: 'Interactivity',
        subTitle: 'Total Blocking Time',
        map: (testRun) => parseFloat(testRun.lighthouse.mobile.audits.find((audit) => audit.id === 'total-blocking-time').numericValue / 1000).toFixed(2),
        suffix: 's',
        aimForHigh: false,
        scoreRange: {
          moderate: {
            low: 0.2,
            high: 0.6,
          },
        },
      }, {
        title: 'Visual Stability',
        subTitle: 'Cumulative Layout Shift',
        map: (testRun) => parseFloat(testRun.lighthouse.mobile.audits.find((audit) => audit.id === 'cumulative-layout-shift').numericValue).toFixed(2),
        scoreRange: {
          moderate: {
            low: 0.1,
            high: 0.25,
          },
        },
      }],
    },
  },
  siege: {
    title: 'micro load test',
    items: [{
      title: 'Transactions',
      subTitle: 'Total',
      map: (testRun) => parseInt(testRun.siege.transactions, 10),
    }, {
      title: 'Transactions',
      subTitle: 'Per Second',
      map: (testRun) => parseInt(testRun.siege.transactionRate, 10),
    }, {
      title: 'Percentage of succesful requests',
      map: (testRun) => parseInt(testRun.siege.availability, 10),
      suffix: '%',
    }, {
      title: 'Amount of simultaneous requests',
      map: (testRun) => parseFloat(testRun.siege.concurrency.toFixed(2)),
    }],
  },
};


export default scoreMappers;
