import styled from 'styled-components';
import React from 'react';
import 'moment/locale/nl';
import scoreMappers from '../ScoreMappers';
import CompareSection from './CompareSection';
import itemBlocksContent from '../itemBlocksContent';
import BottomContentSection from './BottomContentSection';

function SpeedSectionComponent({ scans, mobileState, mainUrl, className }) {
  const scoreMapping = mobileState
    ? scoreMappers.speed.mobile
    : scoreMappers.speed.desktop;
  return (
    <div className={className}>
      <ContentContainer>
        <div>
          <CompareSection
            mapper={scoreMapping}
            scans={scans}
            mainUrl={mainUrl}
          />
        </div>
        <BottomContentSection items={itemBlocksContent.speed} />
      </ContentContainer>
    </div>
  );
}

const ContentContainer = styled.div`
  padding: 0 4rem 0 7rem;
`;

const SpeedSection = styled(SpeedSectionComponent)``;

export default SpeedSection;
