import styled from 'styled-components';
import React from 'react';

function ScoreBlockComponent({
  item,
  scan,
  className,
}) {
  const displayScore = item.map(scan);
  const min = item.scoreRange.moderate.low;
  const max = item.scoreRange.moderate.high;

  let resultColor;
  if (displayScore <= min) {
    resultColor = '#F61212';
  } else if (displayScore >= max) {
    resultColor = '#24CD44';
  } else {
    resultColor = '#F49706';
  }
  const style = {
    color: resultColor
  };

  return (
    <div className={className}>
      <h2>
        {item.title}
      </h2>
      <div className="flex">
        <h1 style={style}>
          {item.map(scan)}
        </h1>
        <h3>
          /100
        </h3>
      </div>
    </div>
  );
}

const ScoreBlock = styled(ScoreBlockComponent)`
  margin-bottom: 1rem;
  background: linear-gradient(#f2f5f7, #f2f5f7) 100% 0% / 92% 100% no-repeat;
  padding-top: 2rem;
  font-family: "Airbnb Cereal App Medium"; 
  
  .flex { 
    display: flex;
  }
  
  h1 {
    font-size: 5rem;
  }
  
  h3 {
    font-size: 2rem;
    color: gray;
    margin-top: 1.3em
  }
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    h1 {
      
    }
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    h1 {
      
    }
  }
`;

export default ScoreBlock;
