const menu = {
  title: 'Performance scan for',
  moment: 'Scan performed',
  items: [{
    title: 'Overall',
    url: 'empty',
    key: 'overallSection',
  }, {
    title: 'Context & UX',
    url: 'empty',
    key: 'contentSection',
  }, {
    title: 'Speed',
    url: 'empty',
    key: 'speedSection',
  }, {
    title: 'Stress test',
    url: 'empty',
    key: 'siegeSection',
  }
  // , 
  // {
  //   title: 'Full dashboard',
  //   url: 'empty',
  //   key: 'dashboardSection',
  // }
],
  extraOptions: [{
    title: 'Share',
    url: 'empty',
  }, {
    title: 'Download',
    url: 'empty',
  }],
};


export default menu;
