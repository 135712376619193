import styled from 'styled-components';
import React from 'react';

function ScoreBarComponent({
  scoreRange,
  suffix,
  className,
}) {
  if (suffix !== '') {
    suffix = ` ${suffix}`;
  }
  return (
    <div className={className}>
      <span>
        {scoreRange.moderate.low} {suffix}
      </span>
      <span className="low">
        {scoreRange.moderate.high} {suffix}
      </span>
      <div className="rangeBar" />
    </div>
  );
}

const ScoreBar = styled(ScoreBarComponent)` 
  font-family: "Airbnb Cereal App Medium";
  font-size: 0.7rem;
  font-weight: 900;
  span {
    margin-left: 25%;
    color: #04AD18;
  }
  
  .low {
    color: #ED0C0C;
  }
  
  .rangeBar {
   background: linear-gradient(90deg, #04AD18 0%, #FFA748 51%, #ED0C0C 100%);
   height: 0.3rem;
  }
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    padding: 0 2rem;
  }
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}),
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    padding: 0;
  }
`;

export default ScoreBar;
